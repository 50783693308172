import React, { useState, useEffect, useRef } from "react";
import { useLocation, useParams } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import { db, rtdb } from "../firebase";
import { useNavigate } from "react-router-dom";
import {
  collection,
  getDocs,
  setDoc,
  deleteDoc,
  arrayUnion,
  doc,
  where,
  query,
  serverTimestamp as firestoreTimestamp,
  updateDoc,
  onSnapshot,
  getDoc,
  addDoc,
  runTransaction,
  limit,
  setLogLevel,
} from "firebase/firestore";
import {
  onValue,
  push,
  ref,
  update,
  onChildAdded,
  orderByKey,
  serverTimestamp as rtdbTimestamp,
  remove,
  set,
  get,
  endAt,
  startAt,
  endBefore,
  startAfter,
  orderByChild,
  limitToFirst,
  equalTo,
  child,
  limitToLast,
  orderByValue,
} from "firebase/database";
import ReactLoading from "react-loading";

const ChatRoomSession = () => {
  const chatContainerRef = useRef(null);
  const textInputRef = useRef(null);
  const {
    user,
    displayName,
    university,
    joinedChatRoomID,
    setTheCurrentPage,
    setJoinedChatRoomID,
  } = UserAuth();
  const [chatSessionStarted, setChatSessionStarted] = useState(false);
  const [chatSessionEnded, setChatSessionEnded] = useState(false);
  const [chatRoomSessionDATA, setChatRoomSessionDATA] = useState(null);

  // message
  const [inputMessage, setInputMessage] = useState("");
  const [messages, setMessages] = useState([]);

  // size handling
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [containerHeight, setContainerHeight] = useState(window.innerHeight);
  const [mobileHeight, setMobileHeight] = useState("86vh");
  const [isUserAtBottom, setIsUserAtBottom] = useState(true);
  const [isUserAtTop, setIsUserAtTop] = useState(false);
  const [oldestTimestamp, setOldestTimestamp] = useState(null);
  const [oldestText, setOldestText] = useState(null);
  const [showAreYouSure, setShowAreYouSure] = useState(false);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      if (inputMessage.trim() === "") {
        e.preventDefault();
      } else {
        e.preventDefault();
        sendMessage();
      }
    }
  };

  const handleScroll = () => {
    if (chatContainerRef.current) {
      const scrollPosition =
        chatContainerRef.current.scrollTop +
        chatContainerRef.current.clientHeight;
      const bottomPosition = chatContainerRef.current.scrollHeight;

      // Set user at bottom if they are within 20px of the bottom
      setIsUserAtBottom(scrollPosition >= bottomPosition - 20);
      setIsUserAtTop(chatContainerRef.current.scrollTop == 0);
    }
  };

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };

  const sendMessage = async () => {
    try {
      if (isMobile) {
        const contentEditableDiv = document.getElementById(
          "yourContentEditableId"
        );
        if (contentEditableDiv) {
          contentEditableDiv.textContent = "";
        }
      }

      const newMessage = {
        text: inputMessage,
        senderID: user.uid,
        senderName: displayName,
        senderUniversity: university,
        timestamp: rtdbTimestamp(),
      };
      setInputMessage("");

      const messageRef = ref(rtdb, `ChatRooms/${joinedChatRoomID}/messages`);

      await push(messageRef, newMessage);

      // Scroll to bottom after sending a message
      scrollToBottom();
    } catch (error) {
      console.log("Error");
    }
  };

  const leaveChatRoom = async () => {
    try {
      // for leaving chatroom
      // decrement the activeusers
      const chatRoomRef = doc(db, "ChatRooms", joinedChatRoomID);

      await updateDoc(chatRoomRef, { ended: true })
        .then(async () => {
          await deleteDoc(chatRoomRef).catch((error) => {
            console.log("Error");
          });
        })
        .catch((error) => {
          console.log("Error");
        });
    } catch (error) {
      console.log("Error");
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [chatSessionStarted]);

  useEffect(() => {
    const container = chatContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
        console.log("listener remvoed");
      }
    };
  }, [chatSessionStarted]);

  useEffect(() => {
    if (chatContainerRef.current && isUserAtBottom) {
      scrollToBottom();
    }
  }, [messages]);

  useEffect(() => {
    if (chatContainerRef.current && isUserAtBottom) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    if (joinedChatRoomID) {
      const chatRoomRef = doc(db, "ChatRooms", joinedChatRoomID);

      // ACTUAL CHATROOM LISTENER
      // SETS CHATROOM STARTED AND CHATROOM ENDED
      const chatRoomSessionUnsubscribe = onSnapshot(
        chatRoomRef,
        (roomSnapshot) => {
          if (roomSnapshot.exists()) {
            console.log("you have started listening to the chatroom");
            setChatSessionStarted(true);
            setChatRoomSessionDATA(roomSnapshot.data());
          } else {
            console.log("the chatroom was disbanded");
            setChatSessionStarted(false);
            // Add function for handling chat room being unavailable
          }
        }
      );

      // MESSAGE LISTENER
      const messagesRef = ref(rtdb, `ChatRooms/${joinedChatRoomID}/messages`);
      const fetchInitialMessages = query(
        messagesRef,
        orderByChild("timestamp")
      );

      // Function to fetch initial messages
      async function fetchInitialMessagesOnce() {
        try {
          await get(fetchInitialMessages).then((snapshot) => {
            // console.log(snapshot.val());
            if (snapshot.exists()) {
              const messagesObj = snapshot.val();
              const messages = Object.values(messagesObj);
              setMessages(messages);
              setOldestTimestamp(messages[29].timestamp);
              setOldestText(messages[29].text);

              const lastMessageTimestamp =
                messages[messages.length - 1].timestamp;
            } else {
              console.log("No messages found");
              const messageUnsubscribe = onChildAdded(
                messagesRef,
                (snapshot) => {
                  const newMessage = snapshot.val();
                  setMessages((prevMessages) => [
                    ...prevMessages,
                    {
                      senderName: newMessage.senderName || "",
                      senderUniversity: newMessage.senderUniversity || "",
                      senderID: newMessage.senderID || "",
                      text: newMessage.text || "",
                      timestamp: newMessage.timestamp || "",
                    },
                  ]);
                }
              );

              return messageUnsubscribe;
            }
          });
        } catch (error) {
          console.error("Error fetching messages:", error);
        }
      }

      fetchInitialMessagesOnce();

      // Fetch initial messages and set up listener
      // Set up listener for new messages
      const messageUnsubscribe = onValue(fetchInitialMessages, (snapshot) => {
        const newMessage = snapshot.val();
        console.log(newMessage);
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            senderName: newMessage.senderName || "",
            senderUniversity: newMessage.senderUniversity || "",
            senderID: newMessage.senderID || "",
            text: newMessage.text || "",
            timestamp: newMessage.timestamp || "",
          },
        ]);
      });

      // const messageUnsubscribe = onChildAdded(
      //   fetchInitialMessages,
      //   (snapshot) => {
      //     const messages = [];
      //     snapshot.forEach((childSnapshot) => {
      //       messages.push(childSnapshot.val());
      //     });
      //     // messages should now be ordered by timestamp
      //     console.log(messages);
      //   }
      // );

      // return messageUnsubscribe;

      // Cleanup function to unsubscribe listeners
      return () => {
        chatRoomSessionUnsubscribe();
        messageUnsubscribe();
      };
    }
  }, [joinedChatRoomID]);

  // useEffect(() => {
  //   if (isUserAtTop) {
  //     const messagesRef = ref(rtdb, `ChatRooms/${joinedChatRoomID}/messages`);
  //     async function fetchOlderMessages() {
  //       try {
  //         const queryRef = query(messagesRef, orderByKey("timestamp"));

  //         // const snapshot = await get(queryRef);
  //         const snapshot = null;
  //         if (snapshot.exists()) {
  //           // const messagesObj = snapshot.val();
  //           // const fetchedMessages = Object.values(messagesObj).sort(
  //           //   (a, b) => a.timestamp - b.timestamp
  //           // );
  //           // // Filter out messages that are already in the state
  //           // const newMessages =   fetchedMessages.filter(
  //           //   (message) =>
  //           //     !messages.some(
  //           //       (existingMessage) =>
  //           //         existingMessage.timestamp === message.timestamp
  //           //     )
  //           // );
  //           // if (newMessages.length > 0) {
  //           //   setMessages((prevMessages) => [...newMessages, ...prevMessages]);
  //           //   setOldestTimestamp(newMessages[0].timestamp);
  //           // }
  //         } else {
  //           console.log("No older messages found");
  //         }
  //       } catch (error) {
  //         console.error("Error fetching older messages:", error);
  //       }
  //     }
  //     fetchOlderMessages();
  //   }
  // }, [isUserAtTop]);

  useEffect(() => {
    if (chatSessionStarted) {
      scrollToBottom();
    }
  }, [chatSessionStarted]);

  return (
    <>
      {chatSessionStarted ? (
        isMobile ? (
          <div style={{ backgroundColor: "#fcf0f8" }}>
            <div
              style={{
                overflowY: "auto",
                paddingLeft: "20px",
                padding: "20px",
                height: mobileHeight,
              }}
              ref={chatContainerRef}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "left",
                }}
              >
                <img
                  src={require("../mainlogo.png")}
                  alt="Logo"
                  style={{
                    borderRadius: "100%",
                    height: "25px",
                    marginRight: "10px",
                  }}
                />
                <p style={{ color: "#004AAD", fontSize: 15 }}>
                  <strong>
                    ChatKOOL's{" "}
                    <span style={{ color: "black", fontWeight: "500" }}>
                      Chatroom
                    </span>
                  </strong>
                </p>
              </div>
              <div
                style={{
                  marginTop: 10,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <p
                  style={{
                    fontSize: "15px",
                    textAlign: "left",
                    margin: 0,
                    padding: 0,
                    fontWeight: "bold",
                  }}
                >
                  {chatRoomSessionDATA.name}
                </p>
                <p
                  style={{
                    fontSize: "12px",
                    textAlign: "left",
                    margin: 0,
                    padding: 0,
                    fontWeight: "400",
                  }}
                >
                  {chatRoomSessionDATA.description}
                </p>
                <p
                  style={{
                    fontSize: "10px",
                    textAlign: "left",
                    margin: 0,
                    padding: 0,
                    fontWeight: "300",
                  }}
                >
                  {chatRoomSessionDATA.joiners.length} users joined
                </p>
                {/* july 18 */}
                {/* continue here */}
                {/* if all user will have the onSnapshot to listen how many users are in the group, that would be very expensive. try migrating the activeusers or joiners to the Realtime Datbase */}
              </div>
              <ul
                style={{ listStyleType: "none", padding: 0, paddingBottom: 20 }}
              >
                {messages.map((message) => (
                  <li
                    key={message.timestamp}
                    style={{
                      textAlign:
                        message.senderID === user.uid ? "right" : "left",
                      padding: "4px",
                    }}
                  >
                    <span
                      style={{
                        backgroundColor:
                          message.senderID === user.uid
                            ? "#004AAD"
                            : "lightgray",
                        color:
                          message.senderID === user.uid ? "white" : "black",
                        borderRadius: "8px",
                        padding: "8px",
                        display: "inline-block",
                        maxWidth: "70%",
                        wordWrap: "break-word",
                        textAlign: "left",
                        marginLeft:
                          message.senderID === user.uid ? "auto" : "0",
                      }}
                    >
                      {message.text}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
            <div
              style={{
                backgroundColor: "#fcf0f8",
                display: "flex",
                flexDirection: "column",
                position: "fixed",
                bottom: 0,
                marginLeft: "2.5%",
                paddingBottom: "10px",
                width: "95%",
              }}
            >
              <div style={{ display: "flex", alignItems: "end" }}>
                {chatSessionEnded ? (
                  <>
                    <button
                      onClick={() => {}}
                      style={{
                        marginLeft: 5,
                        flex: 1,
                        height: "40px",
                        borderRadius: "8px",
                        backgroundColor: "#004AAD",
                        color: "white",
                        borderWidth: 0,
                      }}
                    >
                      <strong>Go back to chatrooms</strong>
                    </button>
                  </>
                ) : (
                  <>
                    {showAreYouSure ? (
                      <button
                        onClick={() => {
                          // add a function here to remove from the list of joiners
                          setJoinedChatRoomID(null);
                          setTheCurrentPage("ChatRooms");
                        }}
                        style={{
                          flex: 0.1,
                          height: "40px",
                          borderRadius: "8px",
                          border: "1px solid #ccc",
                          marginRight: "5px",
                        }}
                      >
                        <strong>Sure?</strong>
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          setShowAreYouSure(true);
                        }}
                        style={{
                          flex: 0.1,
                          height: "40px",
                          borderRadius: "8px",
                          border: "1px solid #ccc",
                          marginRight: "5px",
                          backgroundColor: "white",
                        }}
                      >
                        <strong>Leave</strong>
                      </button>
                    )}
                    <div
                      id="yourContentEditableId"
                      contentEditable
                      value={inputMessage}
                      onInput={(e) => {
                        if (showAreYouSure) {
                          setShowAreYouSure(false);
                        }
                        setInputMessage(e.target.textContent);
                      }}
                      onKeyDown={handleKeyPress}
                      style={{
                        flex: 0.7,
                        padding: "10px",
                        marginRight: "5px",
                        border: "1px solid #000",
                        borderRadius: "8px",
                        maxHeight: "50px",
                        overflowY: "auto",
                        backgroundColor: "white",
                      }}
                    />
                    <button
                      onClick={sendMessage}
                      disabled={!inputMessage.trim()}
                      style={{
                        flex: 0.2,
                        backgroundColor: "white",
                        height: "40px",
                        borderRadius: "8px",
                        border: "1px solid #ccc",
                      }}
                    >
                      <strong>Send</strong>
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        ) : (
          // desktop/laptop
          <div
            style={{
              display: "flex",
              width: "100%",
              backgroundColor: "#c7c7c7",
            }}
          >
            <div
              style={{
                width: "35%",
                margin: "0 auto",
                display: "flex",
                flexDirection: "column",
                height: "100vh",
                justifyContent: "space-between",
                backgroundColor: "#fcf0f8",
              }}
            >
              <div
                style={{
                  paddingRight: "10px",
                  paddingLeft: "10px",
                  paddingBottom: 10,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "left",
                    marginLeft: 20,
                  }}
                >
                  <img
                    src={require("../mainlogo.png")}
                    alt="Logo"
                    style={{
                      borderRadius: "100%",
                      height: "25px",
                      marginRight: "10px",
                    }}
                  />
                  <p style={{ color: "#004AAD", fontSize: 15 }}>
                    <strong>
                      ChatKOOL's{" "}
                      <span style={{ color: "black", fontWeight: "500" }}>
                        Chatroom
                      </span>
                    </strong>
                  </p>
                </div>
                <div
                  style={{
                    marginTop: 10,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                    justifyContent: "left",
                  }}
                >
                  <p
                    style={{
                      fontSize: "15px",
                      textAlign: "left",
                      margin: 0,
                      padding: 0,
                      fontWeight: "bold",
                    }}
                  >
                    {chatRoomSessionDATA.name}
                  </p>
                  <p
                    style={{
                      fontSize: "12px",
                      textAlign: "left",
                      margin: 0,
                      padding: 0,
                      fontWeight: "400",
                    }}
                  >
                    {chatRoomSessionDATA.description}
                  </p>
                  <p
                    style={{
                      fontSize: "10px",
                      textAlign: "left",
                      margin: 0,
                      padding: 0,
                      fontWeight: "300",
                    }}
                  >
                    {chatRoomSessionDATA.joiners.length} users joined
                  </p>
                </div>
              </div>
              <div
                style={{
                  flex: 1,
                  overflowY: "scroll",
                  paddingTop: "20px",
                  paddingRight: "10px",
                  paddingLeft: "10px",
                }}
                ref={chatContainerRef}
              >
                <ul style={{ listStyleType: "none", padding: 0 }}>
                  {messages.map((message, index) => {
                    const isSameSenderAsPrevious =
                      index > 0 &&
                      messages[index - 1].senderID === message.senderID;

                    return (
                      <li
                        key={message.timestamp}
                        style={{
                          textAlign:
                            message.senderID === user.uid ? "right" : "left",
                          padding: 2,
                          // border: "1px solid black",
                        }}
                      >
                        {!isSameSenderAsPrevious &&
                        message.senderID !== user.uid ? (
                          <div
                            style={{
                              display: "flex",
                              marginTop: 10,
                            }}
                          >
                            {message.senderUniversity !==
                              "I prefer not to disclose" &&
                            message.senderUniversity !== null ? (
                              <img
                                style={{ height: 20 }}
                                src={require(`../assets/universities/${message.senderUniversity}.png`)}
                                alt={message.senderUniversity}
                              />
                            ) : null}
                            <p
                              style={{
                                margin: 0,
                                padding: 0,
                                marginLeft: 2,
                                fontWeight: "400",
                                fontSize: 13,
                                color: "gray",
                                marginBottom: 5,
                              }}
                            >
                              {message.senderName}
                            </p>
                          </div>
                        ) : null}
                        <span
                          style={{
                            backgroundColor:
                              message.senderID === user.uid
                                ? "#004AAD"
                                : "lightgray",
                            color:
                              message.senderID === user.uid ? "white" : "black",
                            borderRadius: "8px",
                            padding: "8px",
                            display: "inline-block",
                            textAlign: "left",
                            maxWidth: "70%", // You can adjust the percentage as needed
                            wordWrap: "break-word", // Ensure long words don't break layout
                          }}
                        >
                          {message.text}
                        </span>
                      </li>
                    );
                  })}
                </ul>
              </div>
              {chatSessionEnded ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    margin: "10px",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <button
                      onClick={() => {
                        setTheCurrentPage("MainMenu");
                      }}
                      style={{
                        marginLeft: 5,
                        flex: 1,
                        height: "40px",
                        borderRadius: "8px",
                        backgroundColor: "#004AAD",
                        color: "white",
                        borderWidth: 0,
                      }}
                    >
                      <strong>Main Menu</strong>
                    </button>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    // margin: "10px",
                    marginLeft: 10,
                    marginRight: 10,
                    marginBottom: 10,
                  }}
                >
                  <div style={{ display: "flex" }}>
                    {showAreYouSure ? (
                      <button
                        onClick={() => {
                          // add a function here to remove from the list of joiners
                          setJoinedChatRoomID(null);
                          setTheCurrentPage("ChatRooms");
                        }}
                        style={{
                          flex: 0.1,
                          height: "40px",
                          border: "1px solid #ccc",
                          marginRight: "5px",
                          borderRadius: 8,
                        }}
                      >
                        <strong>Sure?</strong>
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          console.log(oldestTimestamp);
                          console.log(oldestText);
                          setShowAreYouSure(true);
                        }}
                        style={{
                          flex: 0.1,
                          height: "40px",
                          backgroundColor: "white",
                          border: "1px solid #ccc",
                          marginRight: "5px",
                          borderRadius: 8,
                        }}
                      >
                        <strong>Leave</strong>
                      </button>
                    )}
                    <input
                      ref={textInputRef}
                      type="text"
                      value={inputMessage}
                      onChange={(e) => {
                        if (showAreYouSure) {
                          setShowAreYouSure(false);
                        }
                        setInputMessage(e.target.value);
                      }}
                      onKeyDown={handleKeyPress}
                      placeholder="Say something"
                      style={{
                        flex: 0.7,
                        padding: "10px",
                        fontSize: "15px",
                        borderRadius: 8,
                      }}
                    />
                    <button
                      onClick={sendMessage}
                      disabled={!inputMessage.trim()}
                      style={{
                        flex: 0.2,
                        height: "40px",
                        marginLeft: "5px",
                        borderRadius: 8,
                      }}
                    >
                      <strong>Send</strong>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        )
      ) : (
        <div
          style={{
            display: "flex",
            // border: "1px solid black",
            height: isMobile ? mobileHeight : "90vh",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            margin: 20,
          }}
        >
          <div
            style={{
              // border: "1px solid black",
              display: "flex",
              flex: 0.1,
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ReactLoading type="bars" color="#FF66C4" />
            </div>
            <p
              style={{
                fontSize: 16,
                fontWeight: "bold",
                color: "#000",
                marginBottom: 0,
                marginTop: 0,
              }}
            >
              Joining the chatroom
            </p>
            <p
              style={{
                fontSize: 10,
                marginTop: 5,
                marginBottom: 5,
                fontWeight: "300",
              }}
            >
              Make sure to follow the chatroom guidelines
            </p>
          </div>
          <div
            style={{
              // border: "1px solid black",
              display: "flex",
              flex: 1,
              width: "90%",
              marginTop: 20,
              alignItems: "center",
              justifyContent: "center",
              marginBottom: 20,
              flexDirection: "column",
            }}
          >
            <p
              style={{
                fontSize: 20,
                color: "gray",
                fontWeight: "400",
                marginTop: 0,
                marginBottom: 0,
              }}
            >
              Place your ad here
            </p>
            <p
              style={{
                fontWeight: "300",
                fontSize: 9,
                marginTop: 5,
                marginBottom: 0,
              }}
            >
              You may contact us through our official facebook page
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default ChatRoomSession;
