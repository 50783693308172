import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import { db } from "../firebase";
import { FaCircle } from "react-icons/fa";
import { MdRefresh } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import GoBack from "../components/GoBack";
import {
  collection,
  getDocs,
  setDoc,
  deleteDoc,
  arrayUnion,
  doc,
  where,
  query,
  serverTimestamp as firestoreTimestamp,
  updateDoc,
  onSnapshot,
  getDoc,
  addDoc,
  runTransaction,
  limit,
  setLogLevel,
  orderBy,
  startAfter,
  increment,
} from "firebase/firestore";
import ReactLoading from "react-loading";

const ChatRooms = () => {
  const {
    user,
    displayName,
    university,
    createdChatroom,
    setCreatedChatroom,
    setTheCurrentPage,
    setJoinedChatRoomID,
  } = UserAuth();
  const [isMobile, setIsMobile] = useState(false);
  const [isCreateChatroomShown, setIsCreateChatroomShown] = useState(false);
  const [chatroomName, setChatRoomName] = useState("");
  const [chatroomDescription, setChatroomDescription] = useState("");
  const [chatRoomDATA, setChatRoomDATA] = useState([]);
  const [isChatRoomsLoading, setIsChatRoomsLoading] = useState(true);
  const [isCreatingChatRoomLoading, setIsCreatingChatRoomLoading] =
    useState(false);
  const [isCreateChatRoomError, setIsCreateChatRoomError] = useState(false);
  const [isCreatedChatRoomLoading, setIsCreatedChatRoomLoading] =
    useState(false);
  const [lastVisible, setLastVisible] = useState(null);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [rerunKey, setRerunKey] = useState(0);
  const [areYouSureToDelete, setAreYouSureToDelete] = useState(false);
  const [submittedChatRoomStatusUpdate, setSubmittedChatRoomStatusUpdate] =
    useState(null);

  const chatContainerRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchInitialChatRooms = async () => {
      setIsChatRoomsLoading(true);
      try {
        const chatRoomsCollection = collection(db, "ChatRooms");
        const initialQuery = query(
          chatRoomsCollection,
          orderBy("createdAt"),
          limit(5)
        );

        const snapshot = await getDocs(initialQuery);
        const chatRoomsData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setChatRoomDATA(chatRoomsData);
        setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
        setIsChatRoomsLoading(false);
      } catch (error) {
        setIsChatRoomsLoading(false);
        console.error("Error fetching chat rooms:", error);
      }
    };

    fetchInitialChatRooms();
  }, [rerunKey]);

  useEffect(() => {
    if (!createdChatroom) {
      if (chatRoomDATA) {
        const userCreatedChatroom = chatRoomDATA.find(
          (chatroom) => chatroom.creator.userID === user.uid
        );

        if (userCreatedChatroom) {
          setCreatedChatroom({
            chatRoomID: userCreatedChatroom.id,
            ...userCreatedChatroom,
          });
        }
      }
    }
  }, [chatRoomDATA]);

  const fetchMoreChatRooms = async () => {
    if (isFetchingMore || !lastVisible) return;
    setIsFetchingMore(true);

    try {
      const chatRoomsCollection = collection(db, "ChatRooms");
      const nextQuery = query(
        chatRoomsCollection,
        orderBy("createdAt"),
        startAfter(lastVisible),
        limit(5)
      );

      const snapshot = await getDocs(nextQuery);
      const moreChatRoomsData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setChatRoomDATA((prevData) => [...prevData, ...moreChatRoomsData]);
      setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
      setIsFetchingMore(false);
    } catch (error) {
      setIsFetchingMore(false);
      console.error("Error fetching more chat rooms:", error);
    }
  };

  // const handleReportChatroom = async (chatroomId) => {
  //   try {
  //     const chatRoomDoc = doc(db, "ChatRooms", chatroomId);
  //     await updateDoc(chatRoomDoc, {
  //       reports: increment(1),
  //     });
  //     console.log(`Chatroom with ID: ${chatroomId} has been reported.`);
  //   } catch (error) {
  //     console.error("Error reporting chatroom:", error);
  //   }
  // };

  const handleScroll = () => {
    console.log("handle scroll is used");
    const container = chatContainerRef.current;
    if (!container || isFetchingMore) return;
    const bottom =
      Math.abs(
        container.scrollHeight - container.scrollTop - container.clientHeight
      ) < 1;
    if (bottom) {
      fetchMoreChatRooms();
    }
  };

  const triggerRerun = () => {
    // console.log("***RERUN IS TRIGGERED***");
    setRerunKey((prevKey) => prevKey + 1);
  };

  useEffect(() => {
    const container = chatContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [lastVisible, isFetchingMore]);

  return (
    <div className="select-rooms">
      <div style={{ maxWidth: 350 }}>
        <GoBack currentPage="Selection" />
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src={require("../mainlogo.png")}
            alt="Logo"
            style={{
              borderRadius: "100%",
              height: "25px",
              marginRight: "10px",
            }}
          />
          <p
            style={{
              fontSize: 15,
            }}
          >
            <strong style={{ color: "#004AAD" }}>ChatKOOL's </strong>
            <span style={{ color: "black" }}>
              <strong>Chatroom</strong>
            </span>
          </p>
        </div>
        <p style={{ margin: 0, padding: 0, fontSize: 13, marginBottom: 10 }}>
          Create or join chatrooms to discuss real-time topics with students
          from universities across the Philippines, for academic help, casual
          conversations, or exploring interests.
        </p>
        <div>
          <div style={{ marginTop: 10 }}>
            <p
              style={{
                fontSize: 12,
                margin: 0,
                marginTop: 5,
                marginBottom: 5,
              }}
            >
              Guidelines:
            </p>
            <ul style={{ margin: 0, paddingInlineStart: "10px" }}>
              <li style={{ fontSize: 11 }}>
                Be polite to other users in chatrooms.
              </li>
              <li style={{ fontSize: 11 }}>
                Respect other users and their opinions.
              </li>
              <li style={{ fontSize: 11 }}>
                Avoid spamming or flooding the chat with messages.
              </li>
              <li style={{ fontSize: 11 }}>
                Do not share personal information or sensitive data.
              </li>
              <li style={{ fontSize: 11 }}>
                Stay on topic and avoid disruptive behavior.
              </li>
              <li style={{ fontSize: 11 }}>
                Use appropriate language and tone.
              </li>
              <li style={{ fontSize: 11 }}>
                Follow community guidelines and rules.
              </li>
              <li style={{ fontSize: 11 }}>
                Help create a positive and inclusive environment for everyone.
              </li>
              <li style={{ fontSize: 11 }}>
                Be mindful of cultural differences and sensitivities.
              </li>
            </ul>
          </div>
        </div>

        <div>
          <p
            style={{
              flex: 1,
              fontSize: 15,
              textAlign: "left",
              fontWeight: "bold",
              marginBottom: 10,
            }}
          >
            Your Chatroom
          </p>
          {createdChatroom ? (
            <div>
              <p
                style={{
                  margin: 0,
                  padding: 0,
                  fontSize: 13,
                  marginBottom: 10,
                }}
              >
                Refreshing or closing the website might result in the loss of
                the chatroom data you created
              </p>
              <p
                style={{
                  margin: 0,
                  padding: 0,
                  fontSize: 13,
                  marginBottom: 10,
                }}
              >
                However, you can still access your created chatroom in the
                Active Chatrooms list. Even if the local data is lost, the
                chatroom will still be visible in the Active Chatrooms list.
              </p>
              <button
                onClick={async () => {
                  try {
                    console.log(createdChatroom.chatRoomID);
                    setIsCreatedChatRoomLoading(true);
                    const chatRoomID = createdChatroom.chatRoomID;
                    const chatRoomDocRef = doc(db, "ChatRooms", chatRoomID);
                    const chatRoomDoc = await getDoc(chatRoomDocRef);

                    if (chatRoomDoc.exists()) {
                      setSubmittedChatRoomStatusUpdate("Approved");
                      console.log(
                        `Chat room with ID ${chatRoomID} is present in the ChatRooms collection.`
                      );
                      setCreatedChatroom((prevState) => ({
                        ...prevState,
                        approved: true,
                        joiners: chatRoomDoc.data().joiners,
                      }));
                      // You can add any additional logic here if the chat room exists
                    } else {
                      console.log(
                        `Chat room with ID ${chatRoomID} is NOT present in the ChatRooms collection.`
                      );
                      console.log(
                        "will check on the status in SubittedChatRooms"
                      );

                      const submittedChatRoomDocRef = doc(
                        db,
                        "SubmittedChatRooms",
                        chatRoomID
                      );
                      const submittedChatRoomDoc = await getDoc(
                        submittedChatRoomDocRef
                      );

                      if (submittedChatRoomDoc.exists()) {
                        setSubmittedChatRoomStatusUpdate("Pending");
                      } else {
                        setCreatedChatroom(null);
                        setSubmittedChatRoomStatusUpdate("Rejected");
                      }

                      // You can add any additional logic here if the chat room does not exist
                    }
                  } catch (error) {
                    console.log(error);
                  } finally {
                    setAreYouSureToDelete(false);
                    setIsCreatedChatRoomLoading(false);
                  }
                }}
                style={{
                  marginBottom: 10,
                  border: "none",
                  backgroundColor: "none",
                  color: "#6b6a6a",
                  borderRadius: 10,
                  fontSize: 12,
                  fontWeight: "bold",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <MdRefresh size={20} />
                <p style={{ fontSize: 12, margin: 5, padding: 0 }}>
                  Check for update
                </p>
              </button>
              {isCreatedChatRoomLoading ? null : submittedChatRoomStatusUpdate ===
                "Approved" ? (
                <p style={{ fontSize: 11, color: "gray" }}>
                  Update: Congratulations! Your chatroom has been approved and
                  is now live. You can tap the "Check for update" button to see
                  the latest number of users in your chatroom.
                </p>
              ) : submittedChatRoomStatusUpdate === "Pending" ? (
                <p style={{ fontSize: 11, color: "gray" }}>
                  Update: The chatroom you created is still to be reviewed by
                  the admin
                </p>
              ) : null}
              {isCreatedChatRoomLoading ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: 30,
                  }}
                >
                  <ReactLoading
                    type="spin"
                    color="#ff66c4"
                    height={25}
                    width={25}
                  />
                </div>
              ) : createdChatroom.approved ? (
                <div
                  style={{
                    backgroundColor: "#5e3b54",
                    borderRadius: 20,
                    padding: 10,
                    marginBottom: 10,
                    textAlign: "left",
                    maxWidth: 350,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: 5,
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <FaCircle color="#0FFF50" size={10} />
                      <p
                        style={{
                          margin: 0,
                          padding: 0,
                          fontSize: 12,
                          fontWeight: "bold",
                          marginTop: 0,
                          marginLeft: 5,
                          color: "white",
                        }}
                      >
                        {createdChatroom.joiners.length} users
                      </p>
                    </div>
                  </div>

                  <div>
                    <div>
                      <h4
                        style={{
                          margin: 0,
                          padding: 0,
                          color: "white",
                          fontSize: 15,
                        }}
                      >
                        {createdChatroom.name}
                      </h4>
                      <p
                        style={{
                          fontSize: 12,
                          margin: 0,
                          padding: 0,
                          marginTop: 0,
                          color: "white",
                        }}
                      >
                        {createdChatroom.description}
                      </p>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <p
                        style={{
                          fontSize: 11,
                          margin: 0,
                          padding: 0,
                          color: "#ccc",
                          marginBottom: 10,
                          marginTop: 10,
                        }}
                      >
                        created by You
                      </p>
                    </div>
                  </div>

                  <button
                    onClick={() => {
                      setJoinedChatRoomID(createdChatroom.chatRoomID);
                      setTheCurrentPage("ChatRoomSession");
                    }}
                    style={{
                      backgroundColor: "#ff66c4",
                      color: "white",
                      border: "none",
                      borderRadius: 10,
                      padding: "8px 16px",
                      fontSize: 12,
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                  >
                    Join
                  </button>
                </div>
              ) : (
                <div
                  style={{
                    backgroundColor: "#3d405b",
                    borderRadius: 20,
                    paddingLeft: 10,
                    paddingTop: 10,
                    paddingRight: 10,
                    marginBottom: 10,
                    textAlign: "left",
                    maxWidth: 350,
                    opacity: 0.5,
                  }}
                >
                  <div>
                    <p
                      style={{
                        margin: 0,
                        padding: 0,
                        fontSize: 11,
                        marginTop: 0,
                        color: "#ccc",
                        marginBottom: 10,
                      }}
                    >
                      Pending review
                    </p>
                    <h4
                      style={{
                        margin: 0,
                        padding: 0,
                        color: "white",
                        fontSize: 15,
                      }}
                    >
                      {createdChatroom.name}
                    </h4>
                    <p
                      style={{
                        fontSize: 12,
                        margin: 0,
                        padding: 0,
                        marginTop: 0,
                        color: "white",
                      }}
                    >
                      {createdChatroom.description}
                    </p>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <p
                      style={{
                        fontSize: 11,
                        margin: 0,
                        padding: 0,
                        color: "#ccc",
                        marginTop: 10,
                        marginBottom: 10,
                      }}
                    >
                      created by You
                    </p>
                  </div>
                </div>
              )}
            </div>
          ) : isCreateChatroomShown ? (
            <div>
              <p
                style={{
                  margin: 0,
                  padding: 0,
                  fontSize: 13,
                  marginBottom: 10,
                }}
              >
                Please be advised that any new chatroom created will be subject
                to admin approval before becoming available to other users.
              </p>
              <div
                style={{
                  display: "flex",
                  maxWidth: "400px",
                  flexDirection: "column",
                }}
              >
                <input
                  type="text"
                  maxLength={50}
                  placeholder="Chatroom Name"
                  onChange={(e) => {
                    const inputValue = e.target.value.toUpperCase();
                    setChatRoomName(inputValue);
                  }}
                  value={chatroomName}
                  style={{
                    flex: 1,
                    padding: 10,
                    borderRadius: "5px",
                    border: "1px solid #ccc",
                    height: "40px",
                    fontSize: "15px",
                  }}
                />

                <textarea
                  maxLength={300}
                  placeholder="What's the chatroom all about?"
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    setChatroomDescription(inputValue);
                  }}
                  value={chatroomDescription}
                  style={{
                    flex: 1,
                    fontFamily: "sans-serif",
                    marginTop: 5,
                    padding: 10,
                    borderRadius: "5px",
                    border: "1px solid #ccc",
                    fontSize: "15px",
                    minHeight: "75px",
                    maxHeight: "150px",
                    overflowY: "auto",
                    resize: "none",
                  }}
                />
              </div>
              {isCreateChatRoomError ? (
                <p style={{ color: "red", fontSize: 12 }}>
                  Error. Please fill up all fields
                </p>
              ) : null}
              <button
                disabled={!user}
                onClick={async () => {
                  if (!chatroomName || !chatroomDescription) {
                    setIsCreateChatRoomError(true);
                    console.error("Chatroom name or description is empty");
                    return;
                  }

                  try {
                    setIsCreatingChatRoomLoading(true);
                    setIsCreateChatRoomError(false);
                    const chatRoomsCollection = collection(
                      db,
                      "SubmittedChatRooms"
                    );
                    const chatRoomRef = await addDoc(chatRoomsCollection, {
                      name: chatroomName,
                      description: chatroomDescription,
                      creator: {
                        displayName: displayName,
                        university: university,
                        userID: user.uid,
                      },
                      createdAt: new Date(),
                      approved: false,
                      joiners: [],
                    });
                    setIsCreatingChatRoomLoading(false);
                    setIsCreateChatroomShown(false);
                    setChatRoomName("");
                    setChatroomDescription("");
                    setCreatedChatroom({
                      chatRoomID: chatRoomRef.id,
                      name: chatroomName,
                      description: chatroomDescription,
                      approved: false,
                      joiners: [],
                    });
                    console.log("Chat room created with ID:", chatRoomRef.id);
                  } catch (error) {
                    console.error("Error creating chat room:", error);
                  }
                }}
                style={{
                  flex: 1,
                  marginTop: 5,
                  paddingLeft: 20,
                  paddingRight: 20,
                  backgroundColor: user ? "#ff66c4" : "#ccc",
                  color: "white",
                  border: "none",
                  borderRadius: "10px",
                  height: "40px",
                  cursor: user ? "pointer" : "not-allowed",
                }}
              >
                {isCreatingChatRoomLoading ? (
                  <ReactLoading
                    type="spin"
                    color="white"
                    height={15}
                    width={15}
                  />
                ) : (
                  <h4 style={{ margin: 0, padding: 0 }}>Create</h4>
                )}
              </button>
            </div>
          ) : (
            <div style={{ marginTop: 10 }}>
              <p
                style={{
                  margin: 0,
                  padding: 0,
                  fontSize: 13,
                  marginBottom: 10,
                }}
              >
                Please be advised that any new chatroom created will be subject
                to admin approval before becoming available to other users.
              </p>
              {submittedChatRoomStatusUpdate === "Rejected" ? (
                <p style={{ fontSize: 11, color: "gray" }}>
                  Update: Unfortunately, the chatroom you submitted did not meet
                  the admin's approval. However, don't be discouraged. You still
                  have the opportunity to craft another meaningful and engaging
                  chatroom.
                </p>
              ) : null}
              <div style={{ display: "flex" }}>
                <button
                  disabled={!user}
                  onClick={() => {
                    setSubmittedChatRoomStatusUpdate(null);
                    setIsCreateChatroomShown(true);
                  }}
                  style={{
                    flex: 1,
                    backgroundColor: user ? "#ff66c4" : "#ccc",
                    color: "white",
                    border: "none",
                    borderRadius: "5px",
                    height: "40px",
                    cursor: user ? "pointer" : "not-allowed",
                    marginBottom: 10,
                  }}
                >
                  <h4 style={{ margin: 0, padding: 0 }}>Create a chatroom</h4>
                </button>
              </div>
            </div>
          )}

          {createdChatroom ? (
            <div>
              {createdChatroom.approved ? (
                <p
                  style={{
                    margin: 0,
                    padding: 0,
                    fontSize: 11,
                    color: "gray",
                    marginBottom: 10,
                  }}
                >
                  Deleting this chatroom will disband the entire room and result
                  in the immediate disconnection of all active users.
                </p>
              ) : null}
              {areYouSureToDelete ? (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <p style={{ fontSize: 13, fontWeight: "bold" }}>
                    Are you sure you want to delete the chatroom?
                  </p>
                  <div style={{ display: "flex" }}>
                    <button
                      style={{
                        backgroundColor: "none",
                        color: "#6b6a6a",

                        border: "none",
                        borderRadius: 10,
                        padding: "8px 16px",
                        fontSize: 12,
                        fontWeight: "bold",
                        cursor: "pointer",
                      }}
                      onClick={async () => {
                        if (createdChatroom.approved) {
                          console.log("created chatroom is approved");
                          try {
                            const chatRoomDocRef = doc(
                              db,
                              "ChatRooms",
                              createdChatroom.chatRoomID
                            );
                            setIsCreatedChatRoomLoading(true);
                            await deleteDoc(chatRoomDocRef);
                            triggerRerun();
                            setIsCreatedChatRoomLoading(false);
                            setIsCreateChatRoomError(false);
                            setAreYouSureToDelete(false);
                            setCreatedChatroom(null);
                            setSubmittedChatRoomStatusUpdate(null);
                          } catch (error) {
                            console.log(error);
                          }
                        } else {
                          console.log("created chatroom is not approved");
                          try {
                            const chatRoomDocRef = doc(
                              db,
                              "SubmittedChatRooms",
                              createdChatroom.chatRoomID
                            );
                            setIsCreatedChatRoomLoading(true);
                            console.log(createdChatroom.chatRoomID);
                            await deleteDoc(chatRoomDocRef);
                            setIsCreatedChatRoomLoading(false);
                            setIsCreateChatRoomError(false);
                            setAreYouSureToDelete(false);
                            setCreatedChatroom(null);
                          } catch (error) {
                            console.log(error);
                          }
                        }
                      }}
                    >
                      Yes
                    </button>
                    <button
                      style={{
                        backgroundColor: "#ff66c4",
                        color: "white",
                        border: "none",
                        borderRadius: 10,
                        padding: "8px 16px",
                        fontSize: 12,
                        fontWeight: "bold",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setAreYouSureToDelete(false);
                      }}
                    >
                      No
                    </button>
                  </div>
                </div>
              ) : (
                <button
                  onClick={async () => {
                    setAreYouSureToDelete(true);
                  }}
                  style={{
                    marginBottom: 10,
                    border: "none",
                    backgroundColor: "#cc3300",
                    color: "white",
                    borderRadius: 10,
                    fontSize: 12,
                    fontWeight: "bold",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <MdDelete size={20} color={"white"} />
                  <p style={{ fontSize: 12, margin: 5, padding: 0 }}>
                    Delete Chatroom
                  </p>
                </button>
              )}
            </div>
          ) : null}
        </div>

        <div style={{ display: "flex" }}>
          <p
            style={{
              flex: 1,
              fontSize: 15,
              textAlign: "left",
              fontWeight: "bold",
              marginBottom: 10,
            }}
          >
            Active Chatrooms
          </p>
        </div>
        <button
          onClick={() => {
            triggerRerun();
          }}
          style={{
            marginBottom: 10,
            border: "none",
            backgroundColor: "none",
            color: "#6b6a6a",
            borderRadius: 10,
            fontSize: 12,
            fontWeight: "bold",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
          }}
        >
          <MdRefresh size={20} />
          <p style={{ fontSize: 12, margin: 5, padding: 0 }}>
            Refresh Chatrooms
          </p>
        </button>
        <p
          style={{
            fontSize: 11,
            margin: 0,
            marginTop: 10,
            fontWeight: "bold",
            color: "gray",
          }}
        >
          All chatrooms will reset at 6:00 AM daily
        </p>
        {isChatRoomsLoading ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 30,
            }}
          >
            <ReactLoading type="spin" color="#ff66c4" height={25} width={25} />
          </div>
        ) : (
          <div
            ref={chatContainerRef}
            style={{ overflowY: "scroll", maxHeight: "400px" }}
          >
            <ul style={{ listStyleType: "none", padding: 0 }}>
              {chatRoomDATA
                .filter(
                  (chatroom) =>
                    !createdChatroom ||
                    chatroom.id !== createdChatroom.chatRoomID
                )
                .map((chatroom) => (
                  <li key={chatroom.id}>
                    <div
                      style={{
                        backgroundColor: "#3d405b",
                        borderRadius: 20,
                        padding: 10,
                        marginBottom: 10,
                        textAlign: "left",
                        maxWidth: 350,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: 5,
                        }}
                      >
                        <div
                          style={{
                            flex: 1,
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <FaCircle color="#0FFF50" size={10} />
                          <p
                            style={{
                              margin: 0,
                              padding: 0,
                              fontSize: 12,
                              fontWeight: "bold",
                              marginTop: 0,
                              marginLeft: 5,
                              color: "white",
                            }}
                          >
                            {chatroom.joiners.length} users
                          </p>
                        </div>
                        {/* <div>
            <p
              style={{
                margin: 0,
                padding: 0,
                fontSize: 10,
                marginTop: 0,
                marginLeft: 5,
                color: "lightgray",
              }}
            >
              {chatroom.reports} reports
            </p>
          </div> */}
                      </div>

                      <div>
                        <div>
                          <h4
                            style={{
                              margin: 0,
                              padding: 0,
                              color: "white",
                              fontSize: 15,
                            }}
                          >
                            {chatroom.name}
                          </h4>
                          <p
                            style={{
                              fontSize: 12,
                              margin: 0,
                              padding: 0,
                              marginTop: 0,
                              color: "white",
                            }}
                          >
                            {chatroom.description}
                          </p>
                        </div>
                        {chatroom.creator.userID === user.uid ? (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <p
                              style={{
                                fontSize: 11,
                                margin: 0,
                                padding: 0,
                                color: "#ccc",
                                marginBottom: 10,
                              }}
                            >
                              created by You
                            </p>
                          </div>
                        ) : (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <p
                              style={{
                                fontSize: 11,
                                margin: 0,
                                padding: 0,
                                color: "#ccc",
                              }}
                            >
                              created by
                            </p>
                            {chatroom.creator.university ===
                              "I prefer not to disclose" ||
                            chatroom.creator.university === null ? null : (
                              <img
                                style={{ height: 30, marginLeft: 5 }}
                                src={require(`../assets/universities/${chatroom.creator.university}.png`)}
                                alt={chatroom.creator.university}
                              />
                            )}
                            <p
                              style={{
                                fontWeight: "bold",
                                fontSize: 13,
                                color: "#ffa100",
                                marginLeft: 2.5,
                              }}
                            >
                              {chatroom.creator.displayName}
                            </p>
                          </div>
                        )}
                      </div>

                      <button
                        onClick={() => {
                          setJoinedChatRoomID(chatroom.id);
                          setTheCurrentPage("ChatRoomSession");
                        }}
                        style={{
                          backgroundColor: "#ff66c4",
                          color: "white",
                          border: "none",
                          borderRadius: 10,
                          padding: "8px 16px",
                          fontSize: 12,
                          fontWeight: "bold",
                          cursor: "pointer",
                        }}
                      >
                        Join
                      </button>
                      {/* <button
          onClick={() => handleReportChatroom(chatroom.id)}
          style={{
            marginLeft: 5,
            backgroundColor: "#fcf0f8",
            color: "black",
            border: "none",
            borderRadius: 10,
            padding: "8px 16px",
            fontSize: 12,
            fontWeight: "bold",
            cursor: "pointer",
          }}
        >
          Report
        </button> */}
                    </div>
                  </li>
                ))}

              {isFetchingMore ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: 30,
                  }}
                >
                  <ReactLoading
                    type="spin"
                    color="#ff66c4"
                    height={25}
                    width={25}
                  />
                </div>
              ) : chatRoomDATA.length >= 1 ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: 10,
                  }}
                >
                  <p
                    style={{
                      margin: 0,
                      padding: 0,
                      fontSize: 11,
                      color: "gray",
                    }}
                  >
                    End of result
                  </p>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: 10,
                  }}
                >
                  <p
                    style={{
                      margin: 0,
                      padding: 0,
                      fontSize: 11,
                      color: "gray",
                    }}
                  >
                    No chatrooms yet. Create one
                  </p>
                </div>
              )}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatRooms;
