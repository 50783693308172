import { useRef, useLayoutEffect, useState } from "react";
import { HiSpeakerphone } from "react-icons/hi";

const Announcement = ({ text, onHowToUseClick, onCommentsClick, endTime }) => {
  const end = new Date(endTime ? endTime._seconds * 1000 : null);
  // Extract hours and minutes
  const hours = end.getHours();
  const minutes = end.getMinutes();
  const formattedEndTime = `${hours % 12 || 12}:${
    minutes < 10 ? "0" + minutes : minutes
  } ${hours < 12 ? "AM" : "PM"}`;

  const announcementRef = useRef(null);

  if (text) {
    // MAXIMUM OF 110 CHARACTERS ONLY
    return (
      <div
        ref={announcementRef}
        style={{
          width: "100%", // Use full width for mobile
          backgroundColor: "#fcce79",
          marginBottom: 2.5, // Add some space below the announcement
          padding: "5px", // Added padding for better appearance
          paddingBottom: "5px",
          boxSizing: "border-box", // Include padding in total width/height
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: 3,
          }}
        >
          <HiSpeakerphone size={15} color={"#696969"} />
        </div>
        {/* <p
          style={{
            margin: 0,
            marginBottom: 5,
            fontSize: 10,
            color: "#696969",
            textAlign: "center",
            fontWeight: "bolder",
          }}
        >
          Loudspeaker
        </p> */}

        <p
          style={{
            margin: 0,
            textAlign: "center",
            fontWeight: "500",
            fontSize: 10,
            color: "#10375C",
            // userSelect: "none",
          }}
        >
          {text}
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "5px", // Added space above this section
          }}
        >
          <div
            style={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              userSelect: "none",
            }}
          ></div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
              userSelect: "none",
            }}
          >
            <button
              onClick={() => {
                onHowToUseClick(true);
              }}
              style={{
                border: "none",
                background: "none",
                backgroundColor: "white",
                borderRadius: 10,
                marginRight: 5,
                cursor: "pointer",
              }}
            >
              <p
                style={{
                  margin: 0,
                  color: "black",
                  fontSize: 8,
                  padding: 2.5,
                  fontWeight: "bold",
                  userSelect: "none",
                }}
              >
                How?
              </p>
            </button>
            {/* Uncomment if needed
      <button
        onClick={() => {
          onCommentsClick(true);
        }}
        style={{
          border: "none",
          background: "none",
          backgroundColor: "white",
          borderRadius: 10,
          cursor: "pointer",
        }}
      >
        <p
          style={{
            margin: 0,
            color: "black",
            fontSize: 10,
            padding: 2.5,
            fontWeight: "bold",
            userSelect: "none",
          }}
        >
          Comments
        </p>
      </button>
      */}
          </div>
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <p style={{ fontSize: 9, color: "gray", margin: 0 }}>
              Ends in{" "}
              <span style={{ fontWeight: "bolder" }}>{formattedEndTime}</span>
            </p>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div
        style={{
          width: "100%", // Use full width for mobile
          backgroundColor: "#ffffff",
          color: "#696969",
          marginBottom: "0px", // Add some space below the announcement
          padding: "5px", // Add padding for better appearance
          boxSizing: "border-box", // Ensure padding is included in total width/height
        }}
      >
        <p
          style={{
            margin: 0,
            textAlign: "center",
            fontSize: 9.5,
            fontWeight: "400",
            userSelect: "none",
          }}
        >
          Wanna announce something here?
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "5px", // Add some space above this section
          }}
        >
          <div
            style={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              userSelect: "none",
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                userSelect: "none",
              }}
            >
              <p
                style={{
                  fontWeight: "bolder",
                  margin: 0,
                  fontSize: 9.5,
                  textAlign: "center",
                  userSelect: "none",
                }}
              >
                Loudspeaker
              </p>
              <p
                style={{
                  margin: 0,
                  textAlign: "center",
                  fontSize: 7.5,
                  fontWeight: "400",
                  fontStyle: "italic",
                  userSelect: "none",
                }}
              >
                Visible to everyone
              </p>
            </div>
            <button
              onClick={() => {
                onHowToUseClick(true);
              }}
              style={{
                border: "none",
                background: "none",
                backgroundColor: "#FF66C4",
                borderRadius: 10,
                marginLeft: 5,
                height: 17,
                cursor: "pointer",
              }}
            >
              <p
                style={{
                  margin: 0,
                  fontSize: 7.5,
                  fontWeight: "bold",
                  userSelect: "none",
                  color: "white",
                }}
              >
                How?
              </p>
            </button>
          </div>
          <div
            style={{ flex: 1, display: "flex", justifyContent: "flex-start" }}
          />
        </div>
      </div>
    );
  }
};

export default Announcement;
