import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import { db } from "../firebase";
import {
  FaCircle,
  FaArrowLeft,
  FaRegHeart,
  FaHeart,
  FaRegComment,
  FaUserCircle,
  FaArrowCircleLeft,
} from "react-icons/fa";
import { IoIosHeartEmpty } from "react-icons/io";
import { CiChat1 } from "react-icons/ci";
import { IoChatboxOutline, IoHeartOutline } from "react-icons/io5";
import { IoMdClose } from "react-icons/io";
import { BsHeart } from "react-icons/bs";
import { MdRefresh } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import GoBack from "../components/GoBack";
import {
  collection,
  getDocs,
  setDoc,
  deleteDoc,
  arrayUnion,
  doc,
  where,
  query,
  serverTimestamp as firestoreTimestamp,
  updateDoc,
  onSnapshot,
  getDoc,
  addDoc,
  runTransaction,
  limit,
  setLogLevel,
  orderBy,
  startAfter,
  increment,
} from "firebase/firestore";
import ReactLoading from "react-loading";
import Navbar from "../components/Navbar";

const Poster = () => {
  const { user, displayName, university, setTheCurrentPage, poster } =
    UserAuth();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 800);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const onBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = "";
    };

    window.addEventListener("beforeunload", onBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
  }, []);

  const EachComment = ({ user, comment }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isTruncated, setIsTruncated] = useState(false);
    const commentRef = useRef(null);

    const maxLines = 3; // Number of lines to show before truncating

    const handleToggleExpand = () => {
      setIsExpanded((prev) => !prev);
    };

    useEffect(() => {
      // Check if the text is truncated
      const checkIfTruncated = () => {
        if (commentRef.current) {
          const element = commentRef.current;
          setIsTruncated(element.scrollHeight > element.clientHeight);
        }
      };

      checkIfTruncated();
      // Re-check on resize or content change
      window.addEventListener("resize", checkIfTruncated);
      return () => window.removeEventListener("resize", checkIfTruncated);
    }, [comment, isExpanded]);

    return (
      <div style={{ marginBottom: 15 }}>
        {/* Upper section: contains the user's name and university */}
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            {user.university === "I prefer not to disclose" ? (
              <FaUserCircle size={30} style={{ marginRight: 5 }} />
            ) : (
              <img
                style={{ height: 30, marginRight: 5 }}
                src={require(`../assets/universities/${user.university}.png`)}
                alt={`${user.university}`}
              />
            )}
            <p
              style={{
                margin: 0,
                fontSize: 12,
                fontWeight: "bold",
                userSelect: "none",
              }}
            >
              {user.displayName}
            </p>
          </div>
          <p
            style={{
              margin: 0,
              marginLeft: 5,
              fontSize: 12,
              color: "gray",
            }}
          >
            {/* For example, 5 hours ago - Implement a proper date/time format */}
            5h ago
          </p>
        </div>

        {/* Middle section: contains the post */}
        <div>
          <p
            ref={commentRef}
            style={{
              color: "#4a4a4a",
              fontSize: 14,
              marginBottom: 0,
              marginTop: 0,
              marginLeft: 35,
              whiteSpace: "pre-wrap",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              WebkitLineClamp: isExpanded ? "unset" : maxLines,
              cursor: "pointer",
              fontFamily: "sans-serif",
              userSelect: "none",
            }}
            onClick={handleToggleExpand}
          >
            {comment.text}
          </p>
          {!isExpanded && isTruncated && (
            <p
              style={{
                // color: "#004AAD",
                color: "black",
                fontWeight: "bold",
                cursor: "pointer",
                fontSize: 12,
                marginTop: 5,
                textAlign: "center",
                userSelect: "none",
              }}
              onClick={handleToggleExpand}
            >
              Show more
            </p>
          )}
        </div>

        {/* Lower section: contains the number of likes and comments */}
        {/* <div style={{ marginTop: 5, display: "flex" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <IoHeartOutline size={20} color={"black"} />
            <p
              style={{
                margin: 0,
                fontSize: 13,
                fontWeight: "bold",
                marginLeft: 2,
                color: "black",
              }}
            >
              {heartQty}
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: 10,
            }}
          >
            <IoChatboxOutline size={20} color={"black"} />
            <p
              style={{
                margin: 0,
                fontSize: 13,
                fontWeight: "bold",
                marginLeft: 2,
                color: "black",
              }}
            >
              {commentQty}
            </p>
          </div>
        </div> */}
      </div>
    );
  };

  return isMobile ? (
    <div
      style={{
        height: isMobile ? "86vh" : "90vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <button
        onClick={() => {
          setTheCurrentPage("ChatSession");
        }}
        style={{
          marginLeft: 10,
          marginTop: 10,
          position: "absolute",
          zIndex: 19,
          border: "none",
          background: "none",
        }}
      >
        <FaArrowCircleLeft size={30} color={"white"} />
        <p
          style={{
            margin: 0,
            fontSize: 10,
            color: "black",
            fontWeight: "400",
          }}
        >
          Go back
        </p>
      </button>
      <div
        style={{
          justifyContent: "center",
          display: "flex",
          backgroundColor: "black",
        }}
      >
        <img
          src={
            "https://firebasestorage.googleapis.com/v0/b/chatkol.appspot.com/o/cb5ce014-0622-48c7-a566-abcb57158911.jfif?alt=media&token=36e2a4ed-daec-4596-b40b-a1bcc39b930f"
          }
          alt="User Poster"
          style={{
            width: "100%",
            minHeight: 200,
            maxHeight: 350,
            height: "auto",
            display: "block",
            objectFit: "contain", // Ensures image maintains aspect ratio
          }}
        />
      </div>
      <div style={{ marginLeft: 10, marginRight: 10 }}>
        <p style={{ textAlign: "center", fontSize: 15, fontWeight: "bold" }}>
          Comments
        </p>
        <div>
          <EachComment
            user={{ displayName: "liezel", university: "DLSU" }}
            comment={{
              text: "LANGHAP SARAP POTA HAHAHAHAHAHAHAHAHAHAHAHHAHAHAH",
            }}
          />
          <EachComment
            user={{ displayName: "giz", university: "PNU" }}
            comment={{ text: "hahahahaha sarap naman ng ad na yan!" }}
          />
          <EachComment
            user={{
              displayName: "WLW",
              university: "I prefer not to disclose",
            }}
            comment={{ text: "hahahahaha sarap naman ng ad na yan!" }}
          />
          <EachComment
            user={{
              displayName: "WLW",
              university: "I prefer not to disclose",
            }}
            comment={{ text: "hahahahaha sarap naman ng ad na yan!" }}
          />
          <EachComment
            user={{
              displayName: "WLW",
              university: "I prefer not to disclose",
            }}
            comment={{ text: "hahahahaha sarap naman ng ad na yan!" }}
          />
          <EachComment
            user={{
              displayName: "WLW",
              university: "I prefer not to disclose",
            }}
            comment={{ text: "hahahahaha sarap naman ng ad na yan!" }}
          />
          <EachComment
            user={{
              displayName: "WLW",
              university: "I prefer not to disclose",
            }}
            comment={{ text: "hahahahaha sarap naman ng ad na yan!" }}
          />
        </div>
      </div>
    </div>
  ) : (
    <div>
      <p>not mobile</p>
    </div>
  );
};

export default Poster;
