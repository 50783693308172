import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import { UserAuth } from "../context/AuthContext"; // Import UserAuth context

const GoBack = ({ currentPage }) => {
  const { setTheCurrentPage } = UserAuth(); // Destructure setTheCurrentPage from UserAuth

  return (
    <button
      onClick={() => {
        setTheCurrentPage(currentPage);
      }}
      style={{
        display: "flex",
        alignItems: "center",
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
        padding: 10,
      }}
    >
      <FaArrowLeft style={{ marginRight: 5 }} />
      <p style={{ margin: 0, padding: 0, fontSize: 15 }}>Go Back</p>
    </button>
  );
};

export default GoBack;
