import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../App.css";
import { UserAuth } from "../context/AuthContext";

const Intro = () => {
  const {
    isOver18,
    isAgree,
    setOver18,
    setAgree,
    currentPage,
    setTheCurrentPage,
  } = UserAuth();

  const handleCheckboxChange = (event) => {
    if (event.target.name === "isOver18") {
      setOver18(!isOver18);
    } else {
      setAgree(!isAgree);
    }
  };

  return (
    <div className="home">
      <img
        src={require("../mainlogo.png")}
        alt="Logo"
        style={{
          display: "block",
          margin: "auto",
          borderRadius: "20px",
          height: "100px",
        }}
      />
      <h1 style={{ marginBottom: "50px" }}>ChatKOOL</h1>

      <p>
        <b>What is ChatKOOL</b>
      </p>

      <p>
        ChatKOOL is a special place just for college and university students in
        the Philippines. It's a place where students can connect, talk about
        their university experiences, help each other with school stuff, and
        make friends with others going through the college journey. Join us on
        ChatKOOL for a friendly community made especially for college and
        university students aged 18 and above. Let's chat, learn, and grow
        together!
      </p>

      <p>
        <b>Terms and Conditions</b>
      </p>

      <p>By using ChatKOOL, you agree to the following terms and conditions:</p>

      <ul>
        <li>
          You must be at least 18 years old to use ChatKOOL. By checking the box
          below, you confirm that you are over 18 years old.
        </li>
        <li>
          You are solely responsible for your interactions and the messages you
          send on ChatKOOL.
        </li>
        <li>
          Do not send any illegal, harmful, threatening, abusive, harassing,
          defamatory, vulgar, obscene, hateful, or racially, ethnically, or
          otherwise objectionable messages on ChatKOOL.
        </li>
        <li>Do not impersonate any other person or entity on ChatKOOL.</li>
        <li>
          We reserve the right to terminate access to ChatKOOL for users who
          violate our community guidelines
        </li>
      </ul>

      <p>
        <b>Disclaimer of Liability</b>
      </p>
      <p>
        ChatKOOL is provided on an "as is" basis. We make no warranties, express
        or implied, about the operation of ChatKOOL or the information, content,
        materials, or products included on ChatKOOL. You expressly agree that
        your use of ChatKOOL is at your sole risk.
      </p>
      <p>
        To the fullest extent permitted by law, we disclaim all liability for
        any direct, indirect, incidental, special, consequential, or exemplary
        damages arising out of or in connection with your use of ChatKOOL,
        whether based on contract, tort, strict liability, or otherwise.
      </p>

      <p>
        <b>Community Guidelines</b>
      </p>
      <p>
        Please be respectful and considerate in all your interactions on
        ChatKOOL, refraining from personal attacks, harassment, or
        discrimination. Keep your posts relevant to the student community, and
        avoid sharing off-topic content, spam, or inappropriate material.
        Remember that your posts in DailyRants are visible to others, so be
        mindful of what you share. Ensure your posts comply with all applicable
        laws, as illegal activities are prohibited. Violations of these
        guidelines may result in content removal or account suspension. Let's
        work together to keep ChatKOOL a safe and supportive space for everyone.
      </p>

      <label>
        <input
          type="checkbox"
          required
          name="isOver18"
          onChange={handleCheckboxChange}
          checked={isOver18}
        />
        I confirm that I am over 18 years old.
      </label>

      <label>
        <input
          type="checkbox"
          required
          name="isAgree"
          onChange={handleCheckboxChange}
          checked={isAgree}
        />
        I agree to ChatKOOL's terms and conditions
      </label>

      <button
        onClick={() => {
          setTheCurrentPage("MainMenu");
        }}
        disabled={!isOver18 || !isAgree}
      >
        <h3>Let's Go</h3>
      </button>
    </div>
  );
};

export default Intro;
