import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import { db, rtdb } from "../firebase";
import { arrayUnion, doc, updateDoc } from "firebase/firestore";
import { ref, set } from "firebase/database";
import Select from "react-select";
import ReactLoading from "react-loading";
import Navbar from "../components/Navbar";

const MainMenu = () => {
  const {
    anonymousSignIn,
    user,
    displayName,
    hasSetIsTyping,
    university,
    hasSuggested,
    setTheCurrentPage,
    setDisplayName,
    setUniversity,
    setHasSetIsTyping,
    setHasSuggested,
  } = UserAuth();
  const [isMobile, setIsMobile] = useState(false);
  const [isSuggestionLoading, setIsSuggestionLoading] = useState(false);
  // const [universitySuggestion, setUniversitySuggestion] = useState("");
  const options = [
    { value: "I prefer not to disclose", label: "I prefer not to disclose" },
    { value: "ADMU", label: "Ateneo De Manila University" },
    { value: "ADU", label: "Adamson University" },
    { value: "AMA", label: "AMA University and Colleges" },
    { value: "APC", label: "Asia Pacific College" },
    { value: "AU", label: "Arellano University" },
    { value: "BATSU", label: "Batangas State University" },
    { value: "BU", label: "Bicol University" },
    { value: "BULSU", label: "Bulacan State University" },
    { value: "CATS", label: "CATS Aero College" },
    { value: "CEU", label: "Centro Escolar University" },
    { value: "CGHC", label: "Chinese General Hospital Colleges" },
    { value: "CIT-U", label: "Cebu Institute of Technology - University" },
    { value: "CSJL", label: "Colegio de San Juan de Letran" },
    { value: "DLSAU", label: "De La Salle Araneta University" },
    { value: "DLS-CSB", label: "De La Salle-College of Saint Benilde" },
    { value: "DLSU", label: "De La Salle University" },
    { value: "DLSU-D", label: "De La Salle University – Dasmariñas" },
    {
      value: "EARIST",
      label: "Eulogio 'Amang' Rodriguez Institute of Science and Technology",
    },
    { value: "EAC", label: "Emilio Aguinaldo College" },
    { value: "EVSU", label: "Eastern Visayas State University" },
    { value: "FEU", label: "Far Eastern University" },
    { value: "HAU", label: "Holy Angel University" },
    { value: "JRU", label: "José Rizal University" },
    { value: "LNU", label: "Leyte Normal University" },
    { value: "LPU", label: "Lyceum of the Philippines University" },
    { value: "MAPUA", label: "Mapúa University" },
    { value: "MC", label: "Miriam College" },
    {
      value: "MSTIP",
      label: "Makati Science Technological Institute of the Philippines",
    },
    { value: "NEU", label: "New Era University" },
    { value: "NTC", label: "National Teachers College" },
    { value: "NU", label: "National University" },
    { value: "OLFU", label: "Our Lady of Fatima University" },
    { value: "PATTS", label: "PATTS College of Aeronautics" },
    { value: "PCU", label: "Philippine Christian University" },
    { value: "PHILSCA", label: "Philippine State College of Aeronautics" },
    { value: "PLM", label: "Pamantasan ng Lungsod ng Maynila" },
    { value: "PLMUN", label: "Pamantasan ng Lungsod ng Muntinlupa" },
    { value: "PLV", label: "Pamantasan ng Lungsod ng Valenzuela" },
    { value: "PNU", label: "Philippine Normal University" },
    { value: "PUP", label: "Polytechnic University of the Philippines" },
    { value: "QCU", label: "Quezon City University" },
    { value: "RTU", label: "Rizal Technological University" },
    { value: "SFAC", label: "Saint Francis of Assisi College" },
    { value: "SBU", label: "San Beda University" },
    { value: "SIC", label: "Santa Isabel College" },
    { value: "SSU", label: "Samar State University" },
    { value: "STI", label: "STI College" },
    { value: "SWU PHINMA", label: "Southwestern University PHINMA" },
    { value: "SJC PHINMA", label: "PHINMA Saint Jude College" },
    { value: "TIP", label: "Technological Institute of the Philippines" },
    { value: "TUP", label: "Technological University of the Philippines" },
    { value: "UB", label: "University of Baguio" },
    { value: "UCC", label: "University of Caloocan City" },
    { value: "UE", label: "University of the East" },
    { value: "UEP", label: "University of Eastern Philippines" },
    { value: "UP", label: "University of the Philippines" },
    { value: "UPHSL", label: "University of Perpetual Help System Laguna" },
    { value: "USA", label: "University of San Agustin" },
    { value: "USC", label: "University of San Carlos" },
    { value: "UST", label: "University of Santo Tomas" },
    { value: "UDM", label: "Universidad de Manila" },
    { value: "WCC", label: "WCC Aeronautical and Technological College" },
  ];

  const AddSuggestionToDB = async (suggestedUniversity) => {
    try {
      setIsSuggestionLoading(true);
      const UniversitySuggestionsRef = doc(db, "Suggestions", "Universities");
      await updateDoc(UniversitySuggestionsRef, {
        ["array-of-suggestions"]: arrayUnion(suggestedUniversity),
      });
      setIsSuggestionLoading(false);
    } catch (error) {
      setIsSuggestionLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (user) {
      if (!hasSetIsTyping) {
        set(ref(rtdb, "UserTypingV5/" + user.uid), {
          isTyping: false,
        })
          .then(() => {
            setHasSetIsTyping(true);
          })
          .catch((error) => {
            console.log("Error");
          });
      }
    } else {
      try {
        anonymousSignIn();
      } catch (error) {
        console.log("Error");
      }
    }
  }, [user]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="select-rooms">
      <Navbar />
      <div style={{ maxWidth: 350, marginBottom: 20, marginTop: 50 }}>
        <p style={{ color: "#000000", fontSize: 20, marginBottom: 5 }}>
          <strong>Create your profile</strong>
        </p>
        <p style={{ fontSize: 13, marginTop: 0 }}>
          Choose your university and a nickname. Keep your identity private for
          a safe and fun community experience!
        </p>
      </div>

      {/* profile */}
      <div style={{ maxWidth: 350 }}>
        {university && displayName !== "" ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: 20,
            }}
          >
            <p
              style={{
                fontSize: 11,
                margin: 0,
                marginBottom: 5,
                fontWeight: "bold",
                color: "gray",
              }}
            >
              This is how other users will see you.
            </p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {university === "I prefer not to disclose" ||
              university === null ? null : (
                <img
                  style={{ height: 35 }}
                  src={require(`../assets/universities/${university}.png`)}
                  alt={university}
                />
              )}
              <p
                style={
                  university === "I prefer not to disclose" ||
                  university === null
                    ? {
                        fontSize: "15px",
                        margin: 0,
                        padding: 0,
                      }
                    : {
                        fontSize: "15px",
                        margin: 0,
                        padding: 0,
                        marginLeft: 10,
                      }
                }
              >
                {displayName}
              </p>
            </div>
          </div>
        ) : null}
        <div style={{ marginBottom: 8 }}>
          <Select
            isSearchable={false}
            blurInputOnSelect={true}
            options={options}
            onChange={(value) => {
              setUniversity(value.value);
            }}
            placeholder={university ? university : "Select your university"}
          />
        </div>
        <div
          style={{
            display: "flex",
            maxWidth: "400px",
          }}
        >
          <input
            type="text"
            maxLength={16}
            placeholder="Enter your display name"
            onChange={(e) => {
              const inputValue = e.target.value;
              // Check if the input contains at least one non-space character
              if (/^\s*$/.test(inputValue)) {
                // If the input consists only of spaces, set the displayName to an empty string
                setDisplayName("");
              } else {
                // Otherwise, update the displayName
                setDisplayName(inputValue);
              }
            }}
            value={displayName}
            style={{
              flex: 1,
              margin: 0,
              paddingTop: 0,
              paddingRight: 0,
              paddingBottom: 0,
              paddingLeft: 10,
              borderRadius: "5px",
              border: "1px solid #ccc",
              height: "40px",
              fontSize: "15px",
            }}
          />
        </div>
        <div style={{ display: "flex", marginTop: 10 }}>
          <button
            onClick={() => {
              setTheCurrentPage("Selection");
            }}
            disabled={
              displayName === "" || !university || !user || isSuggestionLoading
            }
            style={{
              flex: 1,
              backgroundColor:
                displayName !== "" && university && user && !isSuggestionLoading
                  ? "#ff66c4"
                  : "#ccc",
              color: "white",
              border: "none",
              borderRadius: "5px",
              height: "40px",
              cursor:
                displayName !== "" && university && user
                  ? "pointer"
                  : "not-allowed",
            }}
          >
            <h3 style={{ margin: 0, padding: 0 }}>Next</h3>
          </button>
        </div>
        {/* 
        <p style={{ fontSize: 15, fontWeight: "bold", textAlign: "center" }}>
          OR
        </p> */}
        {/* <div style={{ display: "flex", marginTop: 10 }}>
          <button
            disabled={!user || isSuggestionLoading}
            onClick={() => {
              setDisplayName("");
              setUniversity(null);
              setTheCurrentPage("ChatSession");
            }}
            style={{
              flex: 1,
              backgroundColor:
                user && !isSuggestionLoading ? "#ff66c4" : "#ccc",
              color: "white",
              border: "none",
              borderRadius: "5px",
              height: "40px",
              cursor: "pointer",
              cursor: user && !isSuggestionLoading ? "pointer" : "not-allowed",
            }}
          >
            <h4 style={{ margin: 0, padding: 0 }}>
              Continue as a total stranger
            </h4>
          </button>
        </div>
        <p style={{ fontSize: 11, color: "gray" }}>
          If you continue as a total stranger, your display name will be labeled
          as "Stranger" and presented in a blue color.
        </p> */}
        {/* {hasSuggested ? (
          <div>
            <p
              style={{ fontSize: 13, textAlign: "center", fontWeight: "bold" }}
            >
              Suggestion sent. Thank you for participating.
            </p>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 20,
            }}
          >
            <p style={{ color: "#000000", fontSize: 13, marginBottom: 0 }}>
              <strong>Suggest a University</strong>
            </p>
            <p style={{ fontSize: 11 }}>
              Is your university missing from the current list? Kindly provide
              its name for us to consider adding it to the list of options
            </p>

            <div
              style={{
                display: "flex",
                width: "100%",
              }}
            >
              <input
                type="text"
                maxLength={100}
                placeholder="University Name"
                onChange={(e) => {
                  setUniversitySuggestion(e.target.value);
                }}
                value={universitySuggestion}
                style={{
                  flex: 1,
                  margin: 0,
                  paddingTop: 0,
                  paddingRight: 0,
                  paddingBottom: 0,
                  paddingLeft: 10,
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  height: "30px",
                  fontSize: "11px",
                }}
              />
              <button
                disabled={universitySuggestion ? "#ff66c4" : "#ccc"}
                style={{
                  flex: 0.2,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: universitySuggestion ? "#ff66c4" : "#ccc",
                  marginLeft: 5,
                  color: "white",
                  border: "none",
                  borderRadius: "5px",
                  height: "30px",
                  cursor: universitySuggestion ? "pointer" : "not-allowed",
                }}
              >
                {isSuggestionLoading ? (
                  <div>
                    <ReactLoading
                      type="spin"
                      color="white"
                      height={30}
                      width={30}
                    />
                  </div>
                ) : (
                  <strong
                    onClick={async () => {
                      try {
                        await AddSuggestionToDB(universitySuggestion);
                        setHasSuggested(true);
                      } catch (error) {
                        console.log("Error");
                      }
                    }}
                  >
                    Send
                  </strong>
                )}
              </button>
            </div>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default MainMenu;
