import ReactDOM from "react-dom/client";
import { Routes, Route } from "react-router-dom";
import { AuthContextProvider } from "./context/AuthContext";
import Home from "./pages/Home";
import MainMenu from "./pages/MainMenu";
import ChatSession from "./pages/ChatSession";
import Protected from "./components/Protected";
import DailyRants from "./pages/DailyRants";

export default function App() {
  return (
    <div>
      <AuthContextProvider>
        <Routes>
          <Route exact path="/" element={<Home />} />
          {/* <Route exact path="/dailyrants" element={<DailyRants />} /> */}
        </Routes>
      </AuthContextProvider>
    </div>
  );
}
